<template>
<v-dialog v-model="showAlert" width="500" persistent>
    <v-card>
        <v-card-title v-if="title">
            {{title}}
        </v-card-title>
        <v-card-text v-html="message"></v-card-text>
        <slot></slot>
        <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn text @click="no">Nein</v-btn>
            <v-btn text @click="yes">Ja</v-btn>
        </v-card-actions>
    </v-card>
</v-dialog>
</template>
<script>
export default {
    data() {
        return {
            showAlert: false
        }
    },
    methods: {
        yes() {
            this.showAlert = false;
            this.$emit("yes");
        },
        no() {
            this.showAlert = false;
            this.$emit("no");
        }
    },
    watch: {
        active() {
            this.showAlert = true;
        }
    },
    props: {
        title: String,
        message: String,
        active: Number
    }
}
</script>
<template>
	<div class="requests">
		<DenseContainer>

		<!-- Filter -->
		<v-card elevation="4" outlined shaped class="rounded-card">
			<v-row align="center" justify="center">
				<v-col cols="2" style="font-size:1em">
                    Testseite
				</v-col>
			</v-row>
		</v-card>

		</DenseContainer>
	</div>
</template>

<script>
import { auth, functions } from '../main'

export default {
	data() {
		return {
			isLoading: true,
			anfragen: [],
			current_anfragen: [],
		}
	},
	computed: {
	
	},
	methods: {
		
	},
	mounted() {
		let self = this;
		console.log('Test.vue mounted');
		var getTestResults = functions.httpsCallable('getTestResults');
		getTestResults({ }).then(response => {
			console.log(response);
		}).catch(ex => {
			console.log('EXC: ' + ex);
		})
	},
};
</script>
<style>
  tr.v-data-table__selected {
    background: #7d92f5 !important;
  }
  tr:hover {
	cursor: pointer;
  }
</style>


<template>
<v-app>
	<AlertDialog :title="alert.title" :message="alert.message" :active="alert.active" :ok="alert.ok">
		<v-btn v-if="alert.signout" text @click="signout">Abmelden</v-btn>
	</AlertDialog>
	<Navigation></Navigation>
	<ScreenSizeWatcher></ScreenSizeWatcher>
	<v-main>
		<v-container app>
			<div v-if="!$store.state.isAuthenticating">
				<router-view id="router-view"></router-view>
			</div>
			<v-row v-else>
				<v-col cols="12" class="text-center">
					<v-progress-circular indeterminate color="primary" :size="50"></v-progress-circular>
				</v-col>
			</v-row>
		</v-container>
	</v-main>
</v-app>
</template>

<script>
import axios from 'axios';
import Navigation from './components/app/Navigation.vue';
import ScreenSizeWatcher from './components/app/ScreenSizeWatcher.vue';
import { auth } from './main'

export default {
	data() {
		return {
			alert: {
				title: "",
				message: "",
				active: 0,
				ok: true,
				signout: false
			}
		}
	},
	methods: {
		networkError() {
			this.alert.title = "Netzwerkfehler";
			this.alert.message = "Es ist ein Problem mit der Verbindung zum Server aufgetreten. Bitte überprüfen Sie Ihre Internetverbindung oder versuchen Sie es später erneut.";
			this.alert.active++;
			this.alert.ok = false;
		},
		signout() {
			loginNew.logout();
		}
	},
	mounted() {
		console.log('App.vue mounted');
		loginNew.init("ppmpro", process.env.VUE_APP_REDIRECT_URI, [], "openid usercentics profile email aid");
		loginNew.onAuthStateChanged((event) => {
			if(!event.detail.selfExecuted && loginNew.setup.authDone) {
				this.$store.state.isSignedInLoginNew = loginNew.isSignedIn();
				if(loginNew.isSignedIn()) {
					this.$store.state.profile = loginNew.getProfile();
				}else{
					this.$store.state.profile = {};
					loginNew.auth();
				}
			}
		});
	},
	watch: {
		"$store.state.isSignedInLoginNew"() {
			this.$store.state.isAuthenticating = true;
			axios.get(process.env.VUE_APP_BACKEND + "api/getIdToken?id=" + loginNew.getIdToken()).then(response => {
				if(response.data.hasOwnProperty("error")) {
					switch(response.data.error) {
						case "no_employee":
						case "no_permissions":
							this.alert.title = "Fehlende Berechtigungen";
							this.alert.message = "Sie haben nicht die erforderlichen Rechte, um auf diese Anwendung zugreifen zu können.";
							this.alert.active++;
							this.alert.ok = false;
							this.alert.signout = true;
							break;
						default:
							this.alert.title = "Anmeldefehler";
							this.alert.message = "Es ist ein unbekanntes Problem bei der Anmeldung aufgetreten. Bitte versuchen Sie es später erneut.";
							this.alert.active++;
							this.alert.ok = false;
							break;
					}
				}else{
					this.$store.state.permissions = response.data.permissions;
					auth.signInWithCustomToken(response.data.id_token).then(user => {
						this.$store.state.isSignedIn = auth.currentUser != null;
						this.$store.state.isAuthenticating = false;
						//this.$router.push("/").catch(err => {});
					}).catch(error => {
						console.info(error)
						this.networkError();
					});
				}
			}).catch(error => {
				this.networkError();
			})
		}
	},
	components: { Navigation, ScreenSizeWatcher }
};
</script>
<style>
html {
	scroll-behavior: smooth;
}
#router-view {
	flex: 1 1 auto;
}
.app-container {
	width: 100%;
	max-width: 1000px!important;
	margin: 0 auto;
}
.v-dialog .theme--dark.v-card, .v-dialog .theme--dark.v-list, .v-dialog .theme--dark.v-stepper {
	background-color: #383838;
}
.theme--dark a {
	color: #fff!important;
}
/* Design-Anpassungen */
.v-application p {
	margin-bottom: 0;
}
</style>
import Vue from 'vue'
import Vuex from 'vuex'

console.log('store.index.js loading');
Vue.use(Vuex)

export default new Vuex.Store({
	state: {
		layout: {
			dense: false
		},
		isSignedIn: false,
		isSignedInLoginNew: false,
		isAuthenticating: true,
		profile: {},
		loginNewApps: []
	},
	mutations: {
		dense (state, value) {
			state.layout.dense = value;
		}
	},
	actions: {
	},
	modules: {
	}
})

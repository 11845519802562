<template>
<div>
    <ChooseAppsDialog @choose="chooseApps" :active="dialogs.chooseApps" :clientIDs="clientIDs"></ChooseAppsDialog>
    <v-dialog v-model="showAlert" width="930" persistent>
        <v-card :loading="isLoading" :disabled="isLoading">
            <v-card-title></v-card-title>
            <v-card-text>
                <v-text-field v-model="title" outlined dense label="Titel"></v-text-field>
                <v-btn color="primary" small @click="dialogs.chooseApps++" class="mr-5">Apps auswählen</v-btn><span>{{clientIDs.length}} ausgewählt</span>
            </v-card-text>
            <VueEditor v-model="content" :editor-toolbar="customToolbar"></VueEditor>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn @click="showAlert = false" text>Abbrechen</v-btn>
                <v-btn @click="save" text>Speichern</v-btn>
                <slot></slot>
            </v-card-actions>
        </v-card>
    </v-dialog>
</div>
</template>
<script>
import { VueEditor } from "vue2-editor";
import { firestore } from '../../main'
import ChooseAppsDialog from './ChooseAppsDialog.vue';

export default {
    data() {
        return {
            isLoading: false,
            showAlert: false,
            title: "",
            content: "",
            clientIDs: [],
            dialogs: {
                chooseApps: 0
            },
            customToolbar: [
                [{ header: [false, 1, 2, 3, 4, 5, 6] }],
                ["bold", "italic", "underline", "strike"],
                [
                    { align: "" },
                    { align: "center" },
                    { align: "right" },
                    { align: "justify" }
                ],
                [{ list: "ordered" }, { list: "bullet" }],
                [{ script: "sub" }, { script: "super" }],
                [{ color: [] }, { background: [] }],
                ["link"],
                ["clean"]
            ]
        }
    },
    props: {
        active: Number,
        block: Object
    },
    methods: {
        chooseApps(clientIDs) {
            this.clientIDs = clientIDs;
        },
        save() {
            this.isLoading = true;
            firestore.collection("blocks").doc(this.block.id).update({
                title: this.title,
                message: this.content,
                apps: this.clientIDs
            }).then(() => {
                this.showAlert = false;
                this.isLoading = false;
            }).catch(err => {
                this.isLoading = false;
            });
        }
    },
    mounted() {
        
    },
    watch: {
        active() {
            this.content = this.block.data.message;
            this.title = this.block.data.title;
            this.clientIDs = this.block.data.apps;
            this.showAlert = true;
            this.isLoading = false;
        }
    },
    components: {
        VueEditor,
        ChooseAppsDialog
    }
}
</script>
import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Startseite from '../views/Startseite.vue'
import Anfragen_IS from '../views/Requests_IS.vue'
import Anfragen_RL from '../views/Requests_RL.vue'
import Kennzahlen from '../views/Kennzahlen.vue'
import Test from '../views/Test.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Startseite',
    component: Startseite
  },
  {
    path: '/requests_is',
    name: 'IS-Relevanz',
    component: Anfragen_IS
  },
  {
    path: '/requests_rl',
    name: 'RL-Relevanz',
    component: Anfragen_RL
  },
  {
    path: '/kennzahlen',
    name: 'Kennzahlen',
    component: Kennzahlen
  },
  {
    path: '/backups',
    name: 'Backups',
    component: () => { return import("../views/Backups.vue") }
  },
  {
    path: '/test',
    name: 'Test',
    component: Test
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router

<template>
	<div class="requests">
		<DenseContainer>

		<!-- Filter -->
		<v-card elevation="4" outlined shaped class="rounded-card">
			<v-row align="center" justify="center">
				<v-col cols="2" style="font-size:1em">
					<v-card-subtitle>Anfragen-Filter:</v-card-subtitle>
				</v-col>
				<v-col cols="4">
					Nur genehmigte: <input type="checkbox" 
						:value="filter.only_approved" 
						v-model="filter.only_approved">
				</v-col>
				<v-col cols="4">
					Nur ungesichtete (IS-Manager): <input type="checkbox" 
						:value="filter.is_manager_not_checked" 
						v-model="filter.is_manager_not_checked">
				</v-col>
			</v-row>
		</v-card>
		<br>

		<!-- DataTable: Anfragen -->
		<v-card elevation="4" outlined shaped class="rounded-card">
			<v-row align="center" justify="center">
				<v-col cols="12">
					<v-data-table
						@click:row="rowClick"
						:loading="isLoading"
						:headers="headers"
						:items="anfragen_filtered"
						:disable-filtering="false"
						:single-select="true"
						class="elevation-1"
						v-model="current_anfragen"
						item-key="id"
						:default-footer="true">
					  <template v-slot:top>
							<v-text-field
								v-model="suchbegriff"
								label="Suchbegriff"
								class="mx-4"
							></v-text-field>
						</template>
					</v-data-table>
				</v-col>
			</v-row>
		</v-card>
		<br>
		
		<!-- Überschreibbare Felder -->
		<v-card elevation="4" outlined shaped class="rounded-card">
			<v-card-title style="font-size:1em">
            	<h2>Felder aktualisieren:</h2>
          	</v-card-title>
			<v-card-text style="font-size:1em">
				<v-row>
					<v-col cols="4">
						Gesichtet durch IS-Manager:
					</v-col>
					<v-col cols="4">
						<input type="checkbox" :value="current_data.gesichtet" 
							v-model="current_data.gesichtet">
					</v-col>
					<v-col cols="4">
						<v-btn @click="saveAnfrage()"
							:disabled="isLoading"
							depressed
							elevation="2"
							large
							color="primary"
							outlined>Speichern</v-btn>
					</v-col>
				</v-row>
			</v-card-text>
		</v-card>
		<br>

		<!-- Read-Only Felder -->
		<v-card elevation="4" outlined shaped class="rounded-card">
			<v-card-title style="font-size:1em">
            	<h2>Weitere Informationen zur Anfrage:</h2>
          	</v-card-title>
			<v-card-text style="font-size:1em">
				<v-row align="center" justify="center">
					<v-col cols="4">
						Anforderungsgrund:
					</v-col>
					<v-col cols="8">
						<textarea v-model="current_data.grund" rows="4" cols="50" 
							disabled style="border:solid 1px"></textarea>
					</v-col>
				</v-row>
				<v-row align="center" justify="center">
					<v-col cols="4">
						Ausgangsituation:
					</v-col>
					<v-col cols="8">
						<textarea v-model="current_data.situation" rows="4" cols="50" 
							disabled style="border:solid 1px"></textarea>
					</v-col>
				</v-row>
				<v-row align="center" justify="center">
					<v-col cols="4">
						Lösungsidee:
					</v-col>
					<v-col cols="8">
						<textarea v-model="current_data.loesidee" rows="4" cols="50" 
							disabled style="border:solid 1px"></textarea>
					</v-col>
				</v-row>
				<v-row align="center" justify="center">
					<v-col cols="4">
						Hauptziel:
					</v-col>
					<v-col cols="8">
						<textarea v-model="current_data.ziele" rows="4" cols="50" 
							disabled style="border:solid 1px"></textarea>
					</v-col>
				</v-row>
			</v-card-text>
		</v-card>
		<br>

		<!-- Anfrage-URL -->
		<v-card elevation="4" outlined shaped class="rounded-card" v-if="current_data.id != -1">
			<v-row align="center" justify="center">
				<v-col cols="12" align="center" justify="center">
					<h2><a target="_blank" :href="anfrage_url">Anfrage in PPM Pro aufrufen</a></h2>
				</v-col>
			</v-row>
		</v-card>

		</DenseContainer>
	</div>
</template>

<script>
import Vue from 'vue'
import axios from 'axios';
import { auth, firestore } from '../main'

export default {
	data() {
		return {
			headers: [
				{
					text: 'Anfrage-ID',
					align: 'start',
					sortable: true,
					value: 'id',
				},
				{ text: 'Anfrage-Name', sortable: true, value: 'beschreibung' },
				{ text: 'Gesichtet (IS-Manager)', sortable: true, value: 'gesichtet', filterable: true },
				{ text: 'Anfragesteller', sortable: true, value: 'anfsteller', filterable: false },
				{ text: 'IT-Anforderungsverantwortlicher', sortable: true, value: 'itanfverant', filterable: false },
				{ text: 'Gate-Status', sortable: true, value: 'gatestatus_deutsch' },
			],
			filter: {
				only_approved: true,
				is_manager_not_checked: true
			},
			suchbegriff: '',
			isLoading: true,
			anfragen: [],
			current_anfragen: [],
			current_data: {
				id: -1,
				gesichtet: 0,
				grund: '',
				loesidee: '',
				situation: '',
				ziele: '',
				notiz_title: '',
				notiz: ''
			}
		}
	},
	computed: {
		anfragen_filtered() {
			let items = [];
			this.anfragen.forEach(item => {
				let gate_filter_checked = false;
				let is_kz_filter_checked = false;
				let search_filter_checked = false;

				//Nur genehmigte Anfragen?
				if(this.filter.only_approved == false){
					gate_filter_checked = true;
				}
				else {
					if(item.gatestatus === 'APPROVED'){
						gate_filter_checked = true;
					}
				}

				//Nur gesichtete Anfragen?
				if(this.filter.is_manager_not_checked == false){
					is_kz_filter_checked = true;
				}
				else {
					if(item.gesichtet == false || item.gesichtet == 'Nein'){
						is_kz_filter_checked = true;
					}
				}
				
				//Suchbegriff Filter
				if(item.beschreibung.toLowerCase().includes(this.suchbegriff.toLowerCase()) || item.id.includes(this.suchbegriff)){
					search_filter_checked = true;
				}

				if(gate_filter_checked && is_kz_filter_checked && search_filter_checked){
					items.push(item);
				} 
			});

			return items;
		},
		anfrage_url() {
			return "https://new.ppmpro.com/home.pa?entityType=intakeRequest&id=" + this.current_data.id;
		}
	},
	methods: {
		rowClick: function (item, row) { 
			let self = this;     
			if(self.isLoading){
				return;
			}
			row.select(true);
			
			self.current_data.grund = item.grund;
			self.current_data.loesidee = item.loesidee;
			self.current_data.situation = item.situation;
			self.current_data.ziele = item.ziele;

			if(item.gesichtet === 0 || item.gesichtet === "Nein")
				self.current_data.gesichtet = 0;
			else
				self.current_data.gesichtet = 1;

			self.current_data.id = item.id;
		},
		custom_filter(id, search, item) {
			let self = this;

			let gate_filter_checked = false;
			let is_kz_filter_checked = false;
			let search_filter_checked = false;

			//Nur genehmigte Anfragen?
			if(self.filter.only_approved == false){
				gate_filter_checked = true;
			}
			else {
				if(item.gatestatus === 'APPROVED'){
					gate_filter_checked = true;
				}
			}

			//Nur gesichtete Anfragen?
			if(self.filter.is_manager_checked == false){
				is_kz_filter_checked = true;
			}
			else {
				if(item.gesichtet == true || item.gesichtet == 'Ja'){
					is_kz_filter_checked = true;
				}
			}
			
			//Suchbegriff Filter
			if(item.beschreibung.toLowerCase().includes(search.toLowerCase()) || item.id.includes(search)){
				search_filter_checked = true;
			}

			if(gate_filter_checked && is_kz_filter_checked && search_filter_checked){
				return true;
			} else {
				return false;
			}
		},
		saveAnfrage() {
			let self = this;
			if(self.isLoading){
				return;
			}
			self.isLoading = true;
			auth.currentUser.getIdToken().then((idtoken) => {
				axios.post(process.env.VUE_APP_BACKEND + "ppm-pro/updateISInAnfrage", {
					idtoken: idtoken,
					anfrage_id: self.current_data.id,
					gesichtet: self.current_data.gesichtet
				})
				.then(response_save => {
					self.isLoading = false;
					const data = {
						timestamp: new Date(),
						gesichtet: self.current_data.gesichtet,
						kennung: this.$store.state.profile.email
					};
					firestore.collection("anfragen_logging").doc(this.current_data.id).set(data)
					Vue.$toast.open('Gespeichert');
				}).catch(ex => {
					console.log('EXC: ' + ex);
					self.isLoading = false;
				})
			})
		}
	},
	mounted() {
		let self = this;
		console.log('Requests.vue mounted');

		if(this.$store.state.permissions.pages.indexOf('requests_is') === -1){
			this.$router.push('/');
			return;
		}
		auth.currentUser.getIdToken().then((idtoken) => {
			axios.get(process.env.VUE_APP_BACKEND + "ppm-pro/getAnfragen?id=" + idtoken)
			.then(response => {
				self.anfragen = response.data;
				self.suchbegriff = '';
				self.isLoading = false;
			}).catch(ex => {
				console.log('EXC: ' + ex);
			})
		})
	},
};
</script>
<style>
  tr.v-data-table__selected {
    background: #7d92f5 !important;
  }
  tr:hover {
	cursor: pointer;
  }
</style>


import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify';
import 'roboto-fontface/css/roboto/roboto-fontface.css'
import '@mdi/font/css/materialdesignicons.css'

import firebase from 'firebase/app';
import 'firebase/firestore';
import "firebase/functions";
import 'firebase/auth';

Vue.config.productionTip = false
console.log('main.js loading');

const app = firebase.initializeApp({
	apiKey: process.env.VUE_APP_FIREBASE_APIKEY,
	authDomain: process.env.VUE_APP_FIREBASE_AUTH_DOMAIN,
	projectId: process.env.VUE_APP_FIREBASE_PROJECTID,
	storageBucket: process.env.VUE_APP_FIREBASE_STORAGEBUCKET,
	messagingSenderId: process.env.VUE_APP_FIREBASE_MESSAGING_SENDER_ID,
	appId: process.env.VUE_APP_APPID
});
const firestore = app.firestore()
const auth = app.auth()
const functions = app.functions("europe-west3")

if (location.hostname == "localhost") {
	firestore.useEmulator("localhost", 8081)
	functions.useEmulator("localhost", 5001)
	auth.useEmulator("http://localhost:9099")
}

export {app, firestore, functions, auth}

// Global components
import DenseContainer from './components/global/DenseContainer';
import AlertDialog from './components/global/AlertDialog';
import ConfirmDialog from './components/global/ConfirmDialog';
import VueToast from 'vue-toast-notification';
import 'vue-toast-notification/dist/theme-sugar.css';
Vue.component("DenseContainer", DenseContainer);
Vue.component("AlertDialog", AlertDialog);
Vue.component("ConfirmDialog", ConfirmDialog);

import VueSelect  from '../node_modules/vue-select';
//window.VueSelect = VueSelect;
Vue.use(VueSelect);
Vue.component('v-select', VueSelect);
Vue.use(VueToast);

new Vue({
	router,
	store,
	vuetify,
	render: h => h(App)
}).$mount('#app')

<template>
	<div class="requests">
		<DenseContainer>

		<!-- DataTable: Anfragen -->
		<v-card elevation="4" outlined shaped class="rounded-card">
			<v-row align="center" justify="center">
				<v-col cols="12">
					<v-data-table
                        :search="suchbegriff"
						@click:row="rowClick"
						:loading="isLoading"
						:headers="headers"
						:items="anfragen"
						:disable-filtering="false"
						:single-select="true"
						class="elevation-1"
						v-model="current_anfragen"
						item-key="id"
						:default-footer="true">
					  <template v-slot:top>
							<v-text-field
								v-model="suchbegriff"
								label="Suchbegriff"
								class="mx-4"
							></v-text-field>
						</template>
					</v-data-table>
				</v-col>
			</v-row>
		</v-card>
		<br>
		
		<!-- Überschreibbare Felder -->
		<v-card elevation="4" outlined shaped class="rounded-card">
			<v-card-title style="font-size:1em">
            	<h2>Felder aktualisieren:</h2>
          	</v-card-title>
			<v-card-text style="font-size:1em">
				<v-row>
					<v-col cols="4">
						Rechnungslegungsrelevant:
					</v-col>
					<v-col cols="4">
						<input type="checkbox" :value="current_data.rl_relevant" 
							v-model="current_data.rl_relevant">
					</v-col>
					<v-col cols="4">
						<v-btn @click="saveAnfrage()"
							:disabled="isLoading"
							depressed
							elevation="2"
							large
							color="primary"
							outlined>Speichern</v-btn>
					</v-col>
				</v-row>
			</v-card-text>
		</v-card>
		<br>

		<!-- Read-Only Felder -->
		<v-card elevation="4" outlined shaped class="rounded-card">
			<v-card-title style="font-size:1em">
            	<h2>Weitere Informationen zur Anfrage:</h2>
          	</v-card-title>
			<v-card-text style="font-size:1em">
				<v-row align="center" justify="center">
					<v-col cols="4">
						Anforderungsgrund:
					</v-col>
					<v-col cols="8">
						<textarea v-model="current_data.grund" rows="4" cols="50" 
							disabled style="border:solid 1px"></textarea>
					</v-col>
				</v-row>
				<v-row align="center" justify="center">
					<v-col cols="4">
						Ausgangsituation:
					</v-col>
					<v-col cols="8">
						<textarea v-model="current_data.situation" rows="4" cols="50" 
							disabled style="border:solid 1px"></textarea>
					</v-col>
				</v-row>
				<v-row align="center" justify="center">
					<v-col cols="4">
						Lösungsidee:
					</v-col>
					<v-col cols="8">
						<textarea v-model="current_data.loesidee" rows="4" cols="50" 
							disabled style="border:solid 1px"></textarea>
					</v-col>
				</v-row>
				<v-row align="center" justify="center">
					<v-col cols="4">
						Hauptziel:
					</v-col>
					<v-col cols="8">
						<textarea v-model="current_data.ziele" rows="4" cols="50" 
							disabled style="border:solid 1px"></textarea>
					</v-col>
				</v-row>
			</v-card-text>
		</v-card>
		<br>

		<!-- Anfrage-URL -->
		<v-card elevation="4" outlined shaped class="rounded-card" v-if="current_data.id != -1">
			<v-row align="center" justify="center">
				<v-col cols="12" align="center" justify="center">
					<h2><a target="_blank" :href="anfrage_url">Anfrage in PPM Pro aufrufen</a></h2>
				</v-col>
			</v-row>
		</v-card>

		</DenseContainer>
	</div>
</template>

<script>
import Vue from 'vue'
import axios from 'axios';
import { auth, firestore } from '../main'
export default {
	data() {
		return {
			headers: [
				{
					text: 'Anfrage-ID',
					align: 'start',
					sortable: true,
					value: 'id',
				},
				{ text: 'Anfrage-Name', sortable: true, value: 'beschreibung' },
				{ text: 'Anfragesteller', sortable: true, value: 'anfsteller', filterable: false },
				{ text: 'Rechnungslegungsrelevant', sortable: true, value: 'rl_relevant', filterable: false },
			],
			suchbegriff: '',
			isLoading: true,
			anfragen: [],
			current_anfragen: [],
			current_data: {
				id: -1,
				gesichtet: 0,
				grund: '',
				loesidee: '',
				situation: '',
				ziele: '',
				notiz_title: '',
				notiz: ''
			}
		}
	},
	computed: {
		anfrage_url() {
			return "https://new.ppmpro.com/home.pa?entityType=intakeRequest&id=" + this.current_data.id;
		}
	},
	methods: {
		rowClick: function (item, row) { 
			let self = this;     
			if(self.isLoading){
				return;
			}
			row.select(true);
			
			self.current_data.grund = item.grund;
			self.current_data.loesidee = item.loesidee;
			self.current_data.situation = item.situation;
			self.current_data.ziele = item.ziele;

            console.log(item.rl_relevant);

			if(item.rl_relevant === 0 || item.rl_relevant == '' || item.rl_relevant === "Nein")
				self.current_data.rl_relevant = 0;
			else
				self.current_data.rl_relevant = 1;

			self.current_data.id = item.id;
		},
		saveAnfrage() {
			let self = this;
            let rl_relevant = self.current_data.rl_relevant;
            if(rl_relevant) {
                rl_relevant = "Ja";
            }
            else {
                rl_relevant = "Nein";
            }
			if(self.isLoading){
				return;
			}
			self.isLoading = true;
			auth.currentUser.getIdToken().then((idtoken) => {
				axios.post(process.env.VUE_APP_BACKEND + "ppm-pro/updateRLInAnfrage", {
					idtoken: idtoken,
					anfrage_id: self.current_data.id,
                    rl_relevant: rl_relevant
				})
				.then(response_save => {
					self.isLoading = false;
					const data = {
						timestamp: new Date(),
						rl_relevant: rl_relevant,
						kennung: this.$store.state.profile.email
					};
					firestore.collection("anfragen_logging").doc(this.current_data.id).set(data)
					Vue.$toast.open('Gespeichert');
				}).catch(ex => {
					console.log('EXC: ' + ex);
					self.isLoading = false;
				})
			})
		}
	},
	mounted() {
		let self = this;
		console.log('Requests.vue mounted');

		if(this.$store.state.permissions.pages.indexOf('requests_rl') === -1){
			this.$router.push('/');
			return;
		}

		auth.currentUser.getIdToken().then((idtoken) => {
			axios.get(process.env.VUE_APP_BACKEND + "ppm-pro/getAnfragen?id=" + idtoken)
			.then(response => {
				self.anfragen = response.data;
				self.suchbegriff = '';
				self.isLoading = false;
			}).catch(ex => {
				console.log('EXC: ' + ex);
			})
		})
	},
};
</script>
<style>
  tr.v-data-table__selected {
    background: #7d92f5 !important;
  }
  tr:hover {
	cursor: pointer;
  }
</style>


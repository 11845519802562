<template>
    <div class="screen-size-watcher"></div>
</template>
<script>
export default {
    data() {
        return {
            currentWith: ""
        }
    },
    methods: {
        onResize() {
            var w = document.documentElement.clientWidth;
            //var h = document.documentElement.clientHeight;
            if(w > 1904) {
                if(this.currentWith != "xl") {
                    this.currentWith = "xl";
                    this.$store.commit("dense", false);
                }
            }else if(w > 1264) {
                if(this.currentWith != "lg") {
                    this.currentWith = "lg";
                    this.$emit("lg");
                    this.$store.commit("dense", false);
                }
            }else if(w > 960) {
                if(this.currentWith != "md") {
                    this.currentWith = "md";
                    this.$emit("md");
                    this.$store.commit("dense", false);
                }
            }else if(w > 600) {
                if(this.currentWith != "sm") {
                    this.currentWith = "sm";
                    this.$store.commit("dense", false);
                }
            }else{
                if(this.currentWith != "xs") {
                    this.currentWith = "xs";
                    this.$store.commit("dense", true);
                }
            }
        }
    },
    mounted() {
        window.addEventListener("resize", this.onResize);
        this.onResize();
    },
    destroyed() {
        window.removeEventListener("resize", this.onResize);
    }
}
</script>
<template>
	<div class="kennzahlen">
		<DenseContainer>
		<div ref="container">
		</div>
		</DenseContainer>
	</div>
</template>

<script>
import Vue from 'vue'
import vuetify from '../plugins/vuetify';
import { firestore } from '../main'
import KennzahlenPerDate from '../components/global/KennzahlenPerDate.vue';

export default {
	data() {
		return {
			kennzahlen_anfragen: [],
			headers_anfragen: [
				{
					text: 'Anfragen (Genehmigt)',
					align: 'start',
					sortable: true,
					value: 'name',
					align: 'end',
					class: 'subtitle-1'
				},
				{ text: 'Anzahl', value: 'count', align: 'end', class: 'subtitle-1' },
				{ text: 'IT-Manntage', value: 'workdays', align: 'end', class: 'subtitle-1' },
				{ text: 'Gesamtkosten', value: 'kosten', align: 'end', class: 'subtitle-1' },
				{ text: 'Quantitativer Nutzen', value: 'quantity_value', align: 'end', class: 'subtitle-1' }
			],
			kennzahlen_projekte: [],
			headers_projekte: [
				{
					text: 'Projekte (Abgeschlossen)',
					align: 'start',
					sortable: true,
					value: 'name',
					align: 'end',
					class: 'subtitle-1'
				},
				{ text: 'Anzahl', value: 'count', align: 'end', class: 'subtitle-1' },
				{ text: 'IT-Manntage', value: 'workdays', align: 'end', class: 'subtitle-1' },
				{ text: 'Gesamtkosten', value: 'kosten', align: 'end', class: 'subtitle-1' },
				{ text: 'Quantitativer Nutzen', value: 'quantity_value', align: 'end', class: 'subtitle-1' }
			],
			isLoading: true,
		}
	},
	methods: {},
	mounted() {
		let self = this;
        console.log('Kennzahlen.vue mounted');

		if(this.$store.state.permissions.pages.indexOf('kennzahlen') === -1){
			this.$router.push('/');
			return;
		}

		firestore.collection("kennzahlen").get().then(entries => {
			const formatter = new Intl.NumberFormat('de-DE', {
				style: 'currency',
				currency: 'EUR',
				minimumFractionDigits: 2
			})
			entries.docs.forEach(entry => {
				let entry_data = entry.data();

				self.kennzahlen_anfragen = 
				[
					{
						name: 'Mini',
						count: entry_data.mini.anzahl_anfragen,
						workdays: parseFloat(entry_data.mini.itmanntage_anfragen).toFixed(2).replace(".",","),
						kosten: formatter.format(parseFloat(entry_data.mini.gesamtkosten_anfragen)),
						quantity_value: formatter.format(parseFloat(entry_data.mini.quantitativernutzen_anfragen))
					},
					{
						name: 'Klein',
						count: entry_data.klein.anzahl_anfragen,
						workdays: parseFloat(entry_data.klein.itmanntage_anfragen).toFixed(2).replace(".",","),
						kosten: formatter.format(parseFloat(entry_data.klein.gesamtkosten_anfragen)),
						quantity_value: formatter.format(parseFloat(entry_data.klein.quantitativernutzen_anfragen))
					},
					{
						name: 'Normal',
						count: entry_data.normal.anzahl_anfragen,
						workdays: parseFloat(entry_data.normal.itmanntage_anfragen).toFixed(2).replace(".",","),
						kosten: formatter.format(parseFloat(entry_data.normal.gesamtkosten_anfragen)),
						quantity_value: formatter.format(parseFloat(entry_data.normal.quantitativernutzen_anfragen))
					},
				];
				self.kennzahlen_projekte = 
				[
					{
						name: 'Mini',
						count: isNaN(entry_data.mini.anzahl_projekte) ? '-' : entry_data.mini.anzahl_projekte,
						workdays: isNaN(entry_data.mini.itmanntage_projekte) ? '-' : parseFloat(entry_data.mini.itmanntage_projekte).toFixed(2).replace(".",","),
						kosten: isNaN(entry_data.mini.gesamtkosten_projekte) ? '-' : formatter.format(parseFloat(entry_data.mini.gesamtkosten_projekte)),
						quantity_value: isNaN(entry_data.mini.quantitativernutzen_projekte) ? '-' : formatter.format(parseFloat(entry_data.mini.quantitativernutzen_projekte))
					},
					{
						name: 'Klein',
						count: isNaN(entry_data.klein.anzahl_projekte) ? '-' : entry_data.klein.anzahl_projekte,
						workdays: isNaN(entry_data.klein.itmanntage_projekte) ? '-' : parseFloat(entry_data.klein.itmanntage_projekte).toFixed(2).replace(".",","),
						kosten: isNaN(entry_data.klein.gesamtkosten_projekte) ? '-' : formatter.format(parseFloat(entry_data.klein.gesamtkosten_projekte)),
						quantity_value: isNaN(entry_data.klein.quantitativernutzen_projekte) ? '-' : formatter.format(parseFloat(entry_data.klein.quantitativernutzen_projekte))
					},
					{
						name: 'Normal',
						count: isNaN(entry_data.normal.anzahl_projekte) ? '-' : entry_data.normal.anzahl_projekte,
						workdays: isNaN(entry_data.normal.itmanntage_projekte) ? '-' : parseFloat(entry_data.normal.itmanntage_projekte).toFixed(2).replace(".",","),
						kosten: isNaN(entry_data.normal.gesamtkosten_projekte) ? '-' : formatter.format(parseFloat(entry_data.normal.gesamtkosten_projekte)),
						quantity_value: isNaN(entry_data.normal.quantitativernutzen_projekte) ? '-' : formatter.format(parseFloat(entry_data.normal.quantitativernutzen_projekte))
					},
				];

				//Überschrift: Tagesdatum
				let title_arr = entry.id.split('_');
				let title = title_arr[2] + '.' + title_arr[1] + '.' + title_arr[0];
			
				var ComponentClass = Vue.extend(KennzahlenPerDate)
				var instance = new ComponentClass({
					propsData: { 
						tables: [
							{
								headers: self.headers_anfragen,
								kennzahlen: self.kennzahlen_anfragen
							},
							{
								headers: self.headers_projekte,
								kennzahlen: self.kennzahlen_projekte
							}
						],
						kennzahlen_date: title
					},
					vuetify
				})
				instance.$slots.default = [ entry.data().mini.anzahl ]
				instance.$mount();
				this.$refs.container.prepend(instance.$el)
			});
		}).catch(error => console.log(error));
	},
	components: { KennzahlenPerDate },
};
</script>
<style>
	tbody tr:nth-of-type(odd) {
		background-color: rgba(0, 0, 0, .05);
	}
</style>

<template>
<v-dialog v-model="showAlert" width="500" persistent scrollable>
    <v-card>
        <v-card-title>Apps auswählen</v-card-title>
        <v-divider></v-divider>
        <v-text-field v-model="searchTerm" solo flat dense label="Suche ..." hide-details></v-text-field>
        <v-divider></v-divider>
        <v-card-text class="pa-0">
            <v-list dense class="py-0">
                <v-list-item-group v-model="choosenApps" multiple active-class="">
                    <v-list-item v-show="app.show" v-for="app in filteredList" :key="app.path">
                        <template v-slot:default="{ active }">
                            <v-list-item-action>
                            <v-checkbox :input-value="active"></v-checkbox>
                            </v-list-item-action>

                            <v-list-item-content>
                            <v-list-item-title>{{app.data.display_name}}</v-list-item-title>
                            <v-list-item-subtitle v-if="app.data.client_description">{{app.data.client_description}}</v-list-item-subtitle>
                            </v-list-item-content>
                        </template>
                    </v-list-item>
                </v-list-item-group>
            </v-list>
        </v-card-text>
        <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn @click="showAlert = false" text>Abbrechen</v-btn>
            <v-btn @click="save" text>OK</v-btn>
            <slot></slot>
        </v-card-actions>
    </v-card>
</v-dialog>
</template>
<script>
import { VueEditor } from "vue2-editor";
import axios from 'axios';

export default {
    data() {
        return {
            isLoading: false,
            showAlert: false,
            searchTerm: "",
            choosenApps: []
        }
    },
    props: {
        active: Number,
        clientIDs: Array
    },
    methods: {
        save() {
            var clientIds = [];
            this.choosenApps.forEach(indexInList => {
                var choosenApp = this.$store.state.loginNewApps[indexInList];
                clientIds[clientIds.length] = choosenApp.id;
            });
            this.showAlert = false;
            this.$emit("choose", clientIds);
        }
    },
    computed: {
        filteredList() {
            var list = [];
            this.$store.state.loginNewApps.forEach(app => {
                if(this.searchTerm == "" || app.search === false || app.data.display_name.toLowerCase().indexOf(this.searchTerm.toLowerCase()) !== -1) {
                    app.show = true;
                }else{
                    app.show = app.id.toLowerCase().indexOf(this.searchTerm.toLowerCase()) !== -1;
                }
                list[list.length] = app;
            });
            return list;
        }
    },
    mounted() {
        
    },
    watch: {
        active() {
            this.showAlert = true;
            this.choosenApps = [];
            this.clientIDs.forEach(clientID => {
                for(var index in this.$store.state.loginNewApps) {
                    if(this.$store.state.loginNewApps[index].id == clientID) {
                        this.$set(this.choosenApps, this.choosenApps.length, parseInt(index));
                        break;
                    }
                }
            });
        }
    },
    components: {
        VueEditor
    }
}
</script>
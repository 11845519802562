<template>
<v-dialog persistent v-model="dialog" width="500">
    <AlertDialog :active="alert.active" :title="alert.title" :message="alert.message"></AlertDialog>
    <v-card :loading="isLoading" :disabled="isLoading">
        <v-card-title>Rechteverwaltung</v-card-title>
        <v-list>
            <v-list-group v-for="permission in permissions" :key="permission._id">
                <template v-slot:activator>
                    <v-list-item class="px-0">
                        <v-list-item-avatar v-if="permission.picture">
                            <v-img :src="permission.picture"></v-img>
                        </v-list-item-avatar>
                        <v-list-item-avatar v-else>
                            <v-icon class="grey lighten-1" dark>mdi-account</v-icon>
                        </v-list-item-avatar>
                        <v-list-item-content>
                            <v-list-item-title>{{permission.name ? permission.name + " " + permission.family_name : permission._id}}</v-list-item-title>
                            <v-list-item-subtitle>{{permission.aid}}</v-list-item-subtitle>
                        </v-list-item-content>
                    </v-list-item>
                </template>
                <v-btn @click="deletePermission(permission)" color="primary" small class="mx-5 mt-1 mb-2">Rechte entfernen</v-btn>
                <v-checkbox v-model="permission.users" label="Rechteverwaltung" hide-details inset class="px-5 my-0" color="primaryLight"></v-checkbox>
                <v-btn @click="savePermission(permission)" color="primary" small class="mx-5 mt-2">Speichern</v-btn>
                <v-divider class="my-2"></v-divider>
            </v-list-group>
            <v-list-item>
                <v-list-item-avatar>
                    <v-icon class="grey lighten-1" dark>mdi-account</v-icon>
                </v-list-item-avatar>
                <v-list-item-content>
                    <v-list-item-subtitle><v-text-field v-model="addPermissionsId" placeholder="Neue A-Kennung"  outlined dense hide-details color="primaryLight"></v-text-field></v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-action>
                    <v-btn icon @click="addPermissions">
                        <v-icon color="primaryLight">mdi-plus</v-icon>
                    </v-btn>
                </v-list-item-action>
            </v-list-item>
        </v-list>
        <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn text @click="dialog = false">OK</v-btn>
        </v-card-actions>
    </v-card>
</v-dialog>
</template>
<script>
import { firestore } from '../../main'
import axios from 'axios';
import AlertDialog from '../global/AlertDialog.vue';

export default {
    data() {
        return {
            alert: {
                title: "",
                message: "",
                active: 0
            },
            isLoading: false,
            dialog: false,
            permissions: {},
            addPermissionsId: "",
            firebase: {
                listener: null
            }
        }
    },
    methods: {
        deletePermission(permission) {
            this.isLoading = true;
            firestore.collection("permissions").doc(permission._id).delete().then(() => {
                this.isLoading = false;
            }).catch(err => {
                this.isLoading = false;
            });
        },
        savePermission(permission) {
            this.isLoading = true;
            firestore.collection("permissions").doc(permission._id).update({
                users: permission.users
            }).then(() => {
                this.isLoading = false;
            }).catch(err => {
                this.isLoading = false;
            });
        },
        addPermissions() {
            if(this.addPermissionsId == "" || this.addPermissionsId.toLowerCase().replace("@itvv.org", "") == "") {
                return;
            }
            this.isLoading = true;
            firebase.auth().currentUser.getIdToken().then(idToken => {
                axios.get(process.env.VUE_APP_BACKEND + "api/getUserInfo?id=" + idToken + "&email=" + this.addPermissionsId.toLowerCase().replace("@itvv.org", "")).then(response => {
                    if(response.data.response === "ok") {
                        firestore.collection("permissions").doc(response.data.user.sub).set({
                            family_name: response.data.user.family_name,
                            name: response.data.user.name,
                            picture: response.data.user.picture,
                            aid: response.data.user.employee.aid,
                            users: false,
                        }).then(() => {
                            this.addPermissionsId = "";
                            this.isLoading = false;
                        }).catch(error => {
                            this.isLoading = false;
                        });
                    }else{
                        this.alert.title = "Mitarbeiter hinzufügen";
                        this.alert.message = "Es wurde kein Mitarbeiter mit dieser Mitarbeiterkennung gefunden. Bitte überprüfen Sie Ihre eingabe und stellen Sie sicher, dass der Mitarbeiter bei Login NEW registriert ist."
                        this.alert.active++;
                        this.isLoading = false;
                    }
                }).catch(err => {
                    console.error(err);
                    this.alert.title = "Mitarbeiter hinzufügen";
                    this.alert.message = "Es ist ein unbekanntes Problem aufgetreten. Bitte versuchen Sie es später erneut."
                    this.alert.active++;
                    this.isLoading = false;
                });
            })
            return;
            
        }
    },
    mounted() {
        this.firebase.listener = firestore.collection("permissions").onSnapshot(docs => {
            this.permissions = {};
            docs.forEach(doc => {
                var newDoc = doc.data(); 
                newDoc._id = doc.id;
                this.$set(this.permissions, doc.id, newDoc);
            });
        })
    },
    destroyed() {
        if(this.firebase.listener != null) {
            this.firebase.listener();
            this.firebase.listener = null;
        }
    },
    watch: {
        active() {
            this.dialog = true;
        }
    },
    props: {
        active: Number
    },
    components: {
        AlertDialog
    }
}
</script>
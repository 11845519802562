<template>
<div id="kennzahl_level">
    <v-card elevation="4" outlined shaped class="rounded-card" fluid>
        <br>
        <v-row align="center" justify="center">
            <h2>{{kennzahlen_date}}</h2>
        </v-row>
        <br>
        <div v-for="table in tables" :key="table.id">
            <v-row align="center" justify="center">
                <v-data-table
                    :headers="table.headers"
                    :items="table.kennzahlen"
                    class="elevation-1"
                    :hide-default-footer="true"
                >
                    <template slot="item.name" slot-scope="data">
                        <div :class="my-border">{{data.item.name}}</div>
                    </template>
                </v-data-table>
            </v-row>
            <br>
        </div>
        <br>
    </v-card>
    <br/>
</div>
</template>
<script>
import { defineComponent } from '@vue/composition-api'

export default defineComponent({
    data: function () {
        return {
            kennzahlen_date: '',
            tables: [],
        }
    },
    props: {
        kennzahlen_date: String,
        tables: Array
    },
    mounted() {
        //console.log(this.kennzahlen);
    }
})
</script>
<style>
    .my-border {
        border-left: 5px solid red;
    }
	tbody tr:nth-of-type(odd) {
		background-color: rgba(0, 0, 0, .05);
	}
</style>

<template>
<div class="home">
	<PermissionsDialog v-if="$store.state.permissions.users" :active="dialogs.permissions"></PermissionsDialog>
	<ContentDialog :active="dialogs.editBlock" :block="dialogs.selectedBlock"></ContentDialog>
	<ConfirmDialog @yes="deleteBlockConfirm" title="Block löschen?" message="Möchten Sie diesen Block wirklich löschen? Dies kann nicht rückgängig gemacht werden!" :active="dialogs.confirmDelete"></ConfirmDialog>
	<DenseContainer v-if="isLoading" class="text-center">
		<v-row>
			<v-col cols="12">
				<v-progress-circular indeterminate color="primary"></v-progress-circular>
			</v-col>
		</v-row>
	</DenseContainer>
	<DenseContainer v-else>
		<v-row>
			<v-col cols="12">
				<TableOfContent :blocks="blocks"></TableOfContent>
			</v-col>
		</v-row>
		<v-row>
			<v-col cols="12">
				<v-btn color="primary" @click="save" :disabled="!pendingChanges" class="mr-2 mt-2"><v-icon left>mdi-content-save</v-icon>Speichern</v-btn>
				<v-btn v-if="$store.state.permissions.users" color="primary" @click="dialogs.permissions++" class="mt-2"><v-icon left>mdi-account-group</v-icon>Rechteverwaltung</v-btn>
			</v-col>
		</v-row>
		<v-row>
			<v-col cols="12" class="text-center">
				<v-btn color="primary" @click="addNewBlock"><v-icon left>mdi-plus</v-icon>Neuen Block hinzufügen</v-btn>
			</v-col>
			<v-col cols="12" v-if="blocks.length == 0">
				<v-alert type="error">Es konnten keine Datenschutzinhalte geladen werden.</v-alert>
			</v-col>
		</v-row>
	</DenseContainer>
</div>
</template>

<script>
import { firestore } from '../main'
import ContentDialog from '../components/dialogs/ContentDialog.vue';
import PermissionsDialog from '../components/dialogs/PermissionsDialog.vue';

export default {
	data() {
		return {
			dialogs: {
				editBlock: 0,
				selectedBlock: null,
				confirmDelete: 0,
				permissions: 0
			},
			isLoading: true,
			blocks: [],
			contentChanged: {},
			pendingChanges: false
		}
	},
	methods: {
		editBlock(block) {
			this.dialogs.selectedBlock = block;
			this.dialogs.editBlock++;
		},
		deleteBlock(block) {
			this.dialogs.selectedBlock = block;
			this.dialogs.confirmDelete++;
		},
		deleteBlockConfirm() {
			firestore.collection("blocks").doc(this.dialogs.selectedBlock.id).delete().then(() => {

			});
		},
		addNewBlock() {
			firestore.collection("blocks").add({
				title: "Neuer Block",
				apps: [],
				message: "",
				order: this.blocks.length,
				visible: false
			}).then(() => {

			}).catch(err => {

			})
		},
		save() {
			var updateDataset = {};
			var index = 0;
			var promises = [];
			this.blocks.forEach(block => {
				block.data.order = index;
				promises[promises.length] = firestore.collection("blocks").doc(block.id).update(block.data);
				index++;
			});
			Promise.all(promises).then(() => {
				this.pendingChanges = false;
			})
		}
	},
	computed: {
		draggableCards: {
			get() {
				return this.blocks;
			},
			set(val) {
				this.pendingChanges = true;
				this.blocks = val;
			}
		}
	},
	mounted() {
		console.log('Home.vue mounted');
	},
	components: { ContentDialog, PermissionsDialog },
};
</script>

<template>
	<div class="startseite text-center">
		<v-progress-circular indeterminate color="primary"></v-progress-circular>
	</div>
</template>

<script>
export default {
	data() {
		return {

		}
	},
	computed: {
		
	},
	methods: {
		
	},
	mounted() {
		try {
            if(this.$store.state.permissions.pages.indexOf('requests_is') != -1){
                this.$router.push('/requests_is');
            }
            else if(this.$store.state.permissions.pages.indexOf('requests_rl') != -1){
                this.$router.push('/requests_rl');
            }
            else if(this.$store.state.permissions.pages.indexOf('kennzahlen') != -1){
                this.$router.push('/kennzahlen');
            }
            else if(this.$store.state.permissions.pages.indexOf('backups') != -1){
                this.$router.push('/backups');
            }
        }
        catch(e){
            console.log(e);
        }
	},
};
</script>
<style>
  tr.v-data-table__selected {
    background: #7d92f5 !important;
  }
  tr:hover {
	cursor: pointer;
  }
</style>

<template>
    <v-app-bar app fixed color="navigation" elevate-on-scroll>
        <DenseContainer class="py-0">
            <v-row no-gutters class="align-center">
                <v-col cols="8" class="line-height-1">
                    <a @click="clickStore"><img class="v-app-bar-new-icon" :src="$vuetify.theme.dark ? images.logoDark : images.logo" width="90px"></a>
                    <span v-if="!$store.state.layout.dense">PPM Pro Middleware</span>
                </v-col>
                <v-col cols="4" class="text-right">
                    <div id="loginnew-dropdown"></div>
                </v-col>
            </v-row>
        </DenseContainer>
        <template v-slot:extension>
            <v-tabs v-model="tab" centered v-if="!$store.state.isAuthenticating" color="primaryLight ">
                <v-tab :disabled="$store.state.permissions.pages.indexOf('requests_is') === -1" @click="$router.push('/requests_is')">IS-Relevanz</v-tab>
                <v-tab :disabled="$store.state.permissions.pages.indexOf('requests_rl') === -1"  @click="$router.push('/requests_rl')">RL-Relevanz</v-tab>
                <v-tab :disabled="$store.state.permissions.pages.indexOf('kennzahlen') === -1" @click="$router.push('/kennzahlen')">Kennzahlen</v-tab>
                <v-tab :disabled="$store.state.permissions.pages.indexOf('backups') === -1" @click="$router.push('/backups')">Backups</v-tab>
            </v-tabs>
        </template>
    </v-app-bar>
</template>
<script>
    export default {
        data() {
            return {
                tab: 0,
                images: {
                    logo: require("@/assets/Logos/logo_colored.svg"),
                    logoDark: require("@/assets/Logos/logo_dark.svg")
                }
            }
        },
        watch: {
            "$route.path"() {
                console.log('Path: ' + this.$route.path + ' Name: ' + this.$route.name);
                this.calculate_tab_index();
            }
        },
        computed: {
            
        },
        methods: {
            clickStore() {
                this.$router.push("/").catch(err => {});
            },
            calculate_tab_index() {
                switch (this.$route.name) {
                    case "Startseite":
                        this.tab = -1;
                        break;
                    case "IS-Relevanz":
                        this.tab = 0;
                        break;
                    case "RL-Relevanz":
                        this.tab = 1;
                        break;
                    case "Kennzahlen":
                        this.tab = 2;
                        break;
                    case "Backups":
                        this.tab = 3;
                        break;
                    default:
                        this.tab = -1;
                        break;
                }
            }
        },
        mounted() {
            this.calculate_tab_index();
        }
    }
</script>
<style>
    /* Fix, damit die Tabs unter dem Login NEW Dialog sind*/
    .v-toolbar__content, .v-toolbar__extension {
        position: initial!important ;
    }
    /* Profiltext unter Profilbild ausblenden, da dort der Platz fehlt */
    .v-toolbar__content .login-new-avatar span.login-new-avatar-below {
        display: none;
    }
    /* Fix, damit die Tabs bei sehr kleinen Handys funktionieren */
    .v-slide-group__prev{
        display: none!important;
    }
    </style>
    <style scoped>
    #loginnew-dropdown {
        z-index: 9999999;
    }
    .line-height-1 {
        line-height: 1;
    }
    /* Login NEW closer fix */
    header.v-app-bar {
        contain: initial;
        transform: none !important;
    }
</style>